<template>
  <b-navbar
    v-on-scroll="handleScroll"
    toggleable="md"
    class="header"
    :class="{
      'header--transparent': !headerMode,
      'header--white': headerMode
    }"
    fixed="top"
  >
    <b-container>
      <!-- logo -->
      <b-navbar-brand
        :title="$t('label.home')"
        class="header__logo-container"
        to="/home"
        @click="closeMenu"
      >
        <b-img
          v-if="activeMode || headerMode"
          :src="require('@/assets/imgs/logo.png')"
          class="header__logo"
          alt="Logo"
        />
        <b-img
          v-else
          :src="require('@/assets/imgs/logo.png')"
          class="header__logo"
          alt="Logo"
        />
      </b-navbar-brand>
      <!-- logo -->

      <!-- collapsable part -->
      <b-collapse
        id="nav-collapse"
        v-model="collapseChecker"
        @click="collapseChecker = !collapseChecker"
        is-nav
      >
        <!-- nav links -->
        <b-navbar-nav class="header__nav-center ml-auto mr-auto">
          <b-nav-item to="/home" exact @click="closeMenu">{{
            $t("label.home")
          }}</b-nav-item>
          <div v-for="(navLink, index) in navLinks" :key="index">
            <b-nav-item v-if="navLink.id == ''" :to="navLink.route">{{
              navLink.title
            }}</b-nav-item>
            <li v-else class="nav-item">
              <a class="nav-link" href="#" v-scroll-to="navLink.id">{{
                navLink.title
              }}</a>
            </li>
          </div>

          <!-- <b-nav-item-dropdown
            id="my-nav-dropdown"
            :text="$t('label.our_work')"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item
              v-for="work in workItems"
              :key="work.index"
              :to="{ name: 'work-details', params: { slug: work.slug } }"
              >{{ work.name }}</b-dropdown-item
            >
          </b-nav-item-dropdown> -->
          <!-- other nav Links -->
        </b-navbar-nav>
        <!-- nav links -->
      </b-collapse>
      <!-- collapsable part -->

      <!-- aligned last nav items (Language) -->
      <b-navbar-nav
        class="header__nav-last"
        :class="{ 'ml-auto': locale === 'en', 'mr-auto': locale === 'ar' }"
      >
        <b-button
          class="lang-btn button button--filled-primary"
          @click="handleLang"
        >
          <i class="icon-language"></i>
          <span class="lang-btn--text">
            {{ locale === "en" ? $t("label.arabic") : $t("label.english") }}
          </span>
        </b-button>
      </b-navbar-nav>
      <!-- aligned last nav items (Language) -->

      <!-- toggle burger -->
      <!-- <b-navbar-toggle
        class="header__burger-btn"
        target="nav-collapse"
        @click="collapseChecker = !collapseChecker"
      ></b-navbar-toggle> -->
      <b-button v-b-modal.modal-1 class="menu-display"
        ><span class="navbar-toggler-icon"></span
      ></b-button>

      <b-modal id="modal-1" class="header__nav-center" hide-footer hide-header>
        <b-nav-item
          class="font-bold list-type"
          to="/home"
          exact
          @click="closeMenu"
          >{{ $t("label.home") }}</b-nav-item
        >
        <div v-for="(navLink, index) in navLinks" :key="index">
          <b-nav-item
            class="font-bold list-type"
            v-if="navLink.id == ''"
            :to="navLink.route"
            >{{ navLink.title }}</b-nav-item
          >
          <li v-else class="nav-item font-bold list-type">
            <a class="nav-link" href="#" v-scroll-to="navLink.id">{{
              navLink.title
            }}</a>
          </li>
        </div>
      </b-modal>
      <!-- toggle burger -->
    </b-container>
  </b-navbar>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";
// import { IndexData } from "@/helpers/apiMethods";

export default {
  name: "Header",
  data() {
    return {
      locale: Cookies.get("language"),
      collapseChecker: false,
      headerMode: false, // transparent => false & white => true
      activeMode: false,
      navLinks: [
        {
          route: "",
          title: this.$t("label.why_us"),
          id: "#why_us"
        },
        {
          route: "/services",
          title: this.$t("label.features"),
          id: "#features"
        },
        {
          route: "/about-us",
          title: this.$t("label.about"),
          id: ""
        },
        {
          route: "/terms",
          title: this.$t("label.conditions"),
          id: ""
        },
        {
          route: "/usage-policy",
          title: this.$t("label.policy"),
          id: ""
        }
      ],
      workItems: []
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        // need to use (to) but don't know what to use it in, so sorry for that ^_^

        if (to.path) {
          if (from.path == "/order" || from.path == "/demo") {
            this.closeOverlay();
          }
        }
        this.checkRoute();
      }
    }
  },
  mounted() {
    this.checkOrderActive();
    this.checkRoute();
  },
  methods: {
    ...mapActions(["setSettings"]),
    closeMenu() {
      this.collapseChecker = false;
    },
    handleLang() {
      this.locale = this.locale === "en" ? "ar" : "en";
      Cookies.set("language", this.locale);
      this.$nextTick(() => this.$store.commit("SET_LANG", this.locale));

      this.closeMenu();
    },
    handleScroll(event, el) {
      if (window.scrollY >= 80) {
        el.classList.add("header--active");
        this.activeMode = true;
      } else {
        el.classList.remove("header--active");
        this.activeMode = false;
      }
    },
    checkOrderActive() {
      if (this.$route.path == "/order" || this.$route.path == "/demo") {
        this.$router.go(-1);
      }
    },
    checkRoute() {
      if (this.$route.name === "home" || this.$route.name === "default") {
        this.headerMode = false;
      } else {
        this.headerMode = true;
      }
    }
  }
};
</script>
